import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import CancelPresentationRoundedIcon from "@mui/icons-material/CancelPresentationRounded";
import AccessibilityNewRoundedIcon from "@mui/icons-material/AccessibilityNewRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import Paper from "@mui/material/Paper";
import MafiTableTasks from "../../../@mafi/MafiTableTasks";
import MafiImageGallery from "../../../@mafi/MafiImageGallery";
import styled from "@emotion/styled";
import TicketThread from "./TicketThread";
import TicketReplays from "./TicketReplays";
import Draggable from "react-draggable";
import TasksAddForm from "../../tasks/widgets/TasksAddForm";
import axios from "axios";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const API_URL = process.env.REACT_APP_API_URL;
const HOST = process.env.REACT_APP_HOST;
let customerUsers = [];
let staffUsers = [];

let ticketId = window.location.search.replace("?", "");

function createDataCustomer(id, name, email, address, phone, organization) {
  return {
    label: name,
    id: id,
    email: email,
    address: address,
    phone: phone,
    organization: organization,
  };
}

function createData(
  id,
  address,
  name,
  department,
  organization,
  role,
  phone,
  email,
  mobile,
  status,
  tickets,
  ticketsCreated,
  tasks
) {
  return {
    id,
    address,
    name,
    department,
    organization,
    role,
    phone,
    email,
    mobile,
    status,
    tickets,
    ticketsCreated,
    tasks,
  };
}

const TicketDetailMain = () => {
  const { t } = useTranslation();
  const [openTask, setOpenTask] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [ticketClose, setTicketClose] = useState(false);
  const [ticketDelete, setTicketDelete] = useState(false);
  const [ticketEdit, setTicketEdit] = useState(false);
  const [ticketReasign, setTicketReasign] = useState(false);
  const [ticketReOpen, setTicketReOpen] = useState(false);
  const [tAgent, setTAgent] = React.useState("");
  const [tAgentName, setTAgentName] = React.useState("");
  const [tDesc, setTDesc] = React.useState();
  const [tIssue, setTIssue] = React.useState("");
  const [tAgentNameTemp, setTAgentNameTemp] = React.useState("");
  const [tempIssue, setTempIssue] = React.useState("");
  const [tempDesc, setTempDesc] = React.useState("");
  const [dataUsers, setdataUsers] = React.useState([]);
  const [ticketData, setTicketData] = React.useState([]);
  const [ticketStatus, setTicketStatus] = React.useState("");
  const [uniqueStaffUsers, setUniqueStaffUsers] = React.useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [replies, setReplies] = useState([]);
  const [currrentReply, setCurrentReply] = useState("");
  const [message, setMessage] = React.useState("");
  let location = useLocation();
  const navigate = useNavigate();
  const userDataStorage = JSON.parse(localStorage.getItem("user"));
  const componentRef = useRef();

  useEffect(() => {
    let dataUsers = [];
    let usersRows = [];
    let ticketDataTemp = {};
    let idTicket = location.search.replace("?", "");
    let gallery = [];
    const getTicketDetails = async () => {
      const response = await axios.get(`${API_URL}tickets/${idTicket}`);

      if (response.data.ticket) {
        ticketDataTemp = response.data.ticket;
        ticketDataTemp.ticketAsignToId = response.data.ticket.staffId;
        setTIssue(response.data.ticket.title);
        setTempIssue(response.data.ticket.title);
        setTAgent(ticketDataTemp.ticketAsignToId);
        setTDesc(response.data.ticket.description);
        setTempDesc(response.data.ticket.description);

        if (response.data.ticket.pics.length > 0) {
          response.data.ticket.pics.forEach((url) => {
            if (url) {
              gallery.push({
                img: `${HOST}${url}?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
              });
            }
          });
          setGalleryImages(gallery);
        }

        if (response.data.ticket.replies.length > 0) {
          if (userDataStorage.role === "Customer") {
            let customerReplies = response.data.ticket.replies.filter(
              (reply) => {
                // console.log(reply);
                return reply.replyType === "Reply";
              }
            );

            setReplies(customerReplies);
          } else {
            setReplies(response.data.ticket.replies);
          }
        }

        let createDate = ticketDataTemp.created.split(".")[0].replace("T", " ");
        let dueDate = ticketDataTemp.dueDate.split(".")[0].replace("T", " ");
        let upDate = ticketDataTemp.updated.split(".")[0].replace("T", " ");

        const responseUsers = await axios.get(`${API_URL}users`);

        dataUsers = responseUsers.data.users;
        dataUsers.forEach((user) => {
          usersRows.push(
            createData(
              user.id,
              user?.address,
              user.name,
              user.department,
              user.organization,
              user.role,
              user.phone,
              user.email,
              user.mobile,
              user.status,
              user.tickets,
              user.ticketsCreated,
              user.tasks
            )
          );
        });

        usersRows.forEach((user) => {
          if (user.role == "Customer" && user.status == "Active") {
            customerUsers.push(
              createDataCustomer(
                user.id,
                user.name,
                user.email,
                user.address,
                user.phone,
                user.organization
              )
            );
          }
          if (
            (user.role == "Admin" || user.role == "Technician") &&
            user.status == "Active"
          ) {
            staffUsers.push(createDataCustomer(user.id, user.name));
          }
        });

        setUniqueStaffUsers([
          ...new Map(staffUsers.map((m) => [m.id, m])).values(),
        ]);

        let technician = staffUsers.find(
          (staff) => staff.id == ticketDataTemp.staffId
        );
        if (technician == undefined){
          technician = {label:'Inactive'}
        }
        let customer = customerUsers.find(
          (customer) => customer.id == ticketDataTemp.customerId
        );

        setTicketData({
          ticketNum: ticketDataTemp.number,
          ticketId: ticketDataTemp.id,
          ticketTitle: ticketDataTemp.title,
          ticketDescription: ticketDataTemp.description,
          ticketStatus: ticketDataTemp.status,
          ticketPriority: ticketDataTemp.priority,
          ticketDepartment: ticketDataTemp.department,
          ticketCreateDay: createDate,
          ticketUser: customer.label,
          ticketUserEmail: customer.email,
          ticketUserAddress: customer.address,
          ticketUserPhone: customer.phone,
          ticketUserOrg: customer.organization,
          ticketSource: ticketDataTemp.source,
          ticketIssue: ticketDataTemp.issue,
          ticketAsignTo: technician.label,
          ticketAsignToId: ticketDataTemp.staffId,
          ticketDueDate: dueDate,
          ticketupDate: upDate,
        });
        setTAgentName(technician.label);
        setTicketStatus(ticketDataTemp.status);
      }
    };
    getTicketDetails();
  }, [location.search]);

  const StyledModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  // const galleryImages = [
  //   {
  //     img: "https://images.pexels.com/photos/1779487/pexels-photo-1779487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //   },
  //   {
  //     img: "https://images.pexels.com/photos/3861458/pexels-photo-3861458.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //   },
  //   {
  //     img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //   },
  //   {
  //     img: "https://images.pexels.com/photos/1194713/pexels-photo-1194713.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //   },
  //   {
  //     img: "https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //   },
  //   {
  //     img: "https://images.pexels.com/photos/1712/sunglasses-apple-iphone-desk.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //   },
  // ];

  // const galleryImages = [];

  const handleUpdateTicket = async () => {
    setTicketEdit(false);
    let idTicket = location.search.replace("?", "");

    let ticketObj = {
      title: tempIssue,
      description: tempDesc,
    };

    try {
      const response = await fetch(API_URL + "tickets/" + idTicket, {
        method: "PATCH",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(ticketObj),
      });
      setTDesc(tempDesc);
      setTIssue(tempIssue);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateIssue = (event) => {
    let updatedIssue = event.target.value;
    setTempIssue(updatedIssue);
    setTIssue(updatedIssue);
  };

  const handleUpdateDesc = (event) => {
    let updatedDesc = event.target.value;
    setTempDesc(updatedDesc);
    setTDesc(updatedDesc);
  };

  const handleCloseTicketClose = async () => {
    setTicketClose(false);
    let idTicket = location.search.replace("?", "");
    const response = await axios.post(`${API_URL}tickets/close/`, {
      id: idTicket,
    });

    if (response.status === 200) {
      // navigate("/");
      // navigate(`/ticketsDetails/?${idTicket}`);
      setTicketStatus(response.data.ticket.status);
    }

  };

  const handleCloseTicketDelete = () => {
    setTicketDelete(false);
  };

  const handleTicketReOpenCancel = () => {
    setTicketReOpen(false);
  }

  const handleTicketReOpen = async() => {
    setTicketReOpen(false);

    let idTicket = location.search.replace("?", "");

    try{
      const response = await axios.get(`${API_URL}tickets/reopen/${idTicket}`);
      if (response.status === 200) {
        // navigate("/");
        // navigate(`/ticketsDetails/?${idTicket}`);
        setTicketStatus(response.data.ticket.status);
      }

    } catch(err){
      console.log(err);
    }
  }

  const handleCloseTicketCancel = () => {
    setTicketClose(false);
  };

  const handleTAgentChange = (event) => {
    setTAgent(event.target.value);
    let newTech = staffUsers.find((tech) => tech.id === event.target.value);
    setTAgentNameTemp(newTech.label);
  };

  const handleTAgentSave = async (event) => {
    setTicketReasign(false);
    let idTicket = location.search.replace("?", "");
    // setTAgentName(event.target.name);
    let staffIdObj = {
      staffId: tAgent,
    };

    try {
      const response = await fetch(API_URL + "tickets/reassign/" + idTicket, {
        method: "PATCH",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(staffIdObj),
      });
      setTAgentName(tAgentNameTemp);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateReply = async () => {
    let idTicket = location.search.replace("?", "");
    try {
      const response = await axios.get(`${API_URL}tickets/${idTicket}`);
      setReplies(response.data.ticket.replies);
    } catch (error) {
      console.log(error);
    }

    setMessage(<Alert severity="success">{t("The-reply-was-created")}</Alert>);

    setTimeout(function () {
      setMessage("");
    }, 3000);
  };

  const handleErrorReply = (message) => {
    setMessage(<Alert severity="error">{t(message)}</Alert>);

    setTimeout(function () {
      setMessage("");
    }, 3000);
  };

  const handlePrintTicket = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Ticket: ' + ticketData.ticketNum
  });

  return (
    <Box class="mt-4">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>
          {t("ticket")} : {ticketData.ticketNum}
        </Typography>
        {userDataStorage.role !== "Customer" && (
          <Box>
            <Stack direction="row" spacing={2}>
              <Fab
                color="primary"
                title={t("Post-reply")}
                onClick={(e) => setOpenReply(true)}
              >
                <ReplyRoundedIcon />
              </Fab>
              {userDataStorage.role === "Admin" && (
                <>
                  <Fab
                    color={
                      ticketData.ticketStatus === "Closed"
                        ? "disabled"
                        : "warning"
                    }
                    title={t("Close-ticket")}
                    onClick={(e) => setTicketClose(true)}
                  >
                    <CancelPresentationRoundedIcon />
                  </Fab>
                  <Fab
                      color={
                        ticketData.ticketStatus === "Closed"
                          ? "secondary"
                          : "disabled"
                      }
                      title={t("ReOpen")}
                      onClick={(e) => setTicketReOpen(true)}
                    >
                      <LockOpenRoundedIcon />
                    </Fab>
                  <Fab
                    color="success"
                    title={t("Reassign")}
                    onClick={(e) => setTicketReasign(true)}
                  >
                    <AccessibilityNewRoundedIcon />
                  </Fab>
                  <Fab
                    color="info"
                    title={t("Edit")}
                    onClick={(e) => setTicketEdit(true)}
                  >
                    <EditRoundedIcon />
                  </Fab>
                  <Fab
                    color="error"
                    title={t("Delete")}
                    onClick={(e) => setTicketDelete(true)}
                  >
                    <DeleteOutlineRoundedIcon />
                  </Fab>
                </>
              )}
              <Fab title={t("Print")}>
                <LocalPrintshopRoundedIcon 
                  onClick={handlePrintTicket}
                   />
              </Fab>
            </Stack>
          </Box>
        )}
      </Stack>

      <Box ref={componentRef} sx={{borderColor: 'grey.300', border: 1, padding:'20px', marginTop:'20px'}}>


      <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
        <Typography variant="h5" class="text-xl pt-4 text-blue-900 font-bold">
          {ticketData.ticketTitle}
        </Typography>
        <Divider />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          className="mt-4"
        >
          {ticketReasign ? (
            <>
              <Typography>{t("Assigned-to")}:</Typography>
              <FormControl>
                <FormHelperText>Assign To: *</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tAgent}
                  name={tAgentName}
                  label="Select an Agent"
                  onChange={handleTAgentChange}
                  required
                >
                  {uniqueStaffUsers.map((tech) => (
                    <MenuItem value={tech.id}>{tech.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                color="error"
                className=" w-1/8 mt-16"
                aria-label="Add"
                type="button"
                onClick={(e) => setTicketReasign(false)}
                size="large"
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="outlined"
                color="success"
                className=" w-1/8 mt-16"
                aria-label="Add"
                type="submit"
                size="large"
                onClick={handleTAgentSave}
              >
                {t("Reassign-Ticket")}
              </Button>
            </>
          ) : (
            <Typography>
              {t("Assing-to")}: {tAgentName}
            </Typography>
          )}

          <Typography>
            {t("Due-Date")} {ticketData.ticketDueDate}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          className="mt-4"
        >
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Status")}
                  </TableCell>
                  <TableCell align="right">{ticketStatus}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Priority")}
                  </TableCell>
                  <TableCell align="right">
                    {ticketData.ticketPriority}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("department")}
                  </TableCell>
                  <TableCell align="right">
                    {ticketData.ticketDepartment}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Source")}
                  </TableCell>
                  <TableCell align="right">{ticketData.ticketSource}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Create-day")}
                  </TableCell>
                  <TableCell align="right">
                    {ticketData.ticketCreateDay}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("User")}
                  </TableCell>
                  <TableCell align="right">{ticketData.ticketUser}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Email")}
                  </TableCell>
                  <TableCell align="right">
                    {ticketData.ticketUserEmail}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Address")}
                  </TableCell>
                  <TableCell align="right">
                    {ticketData.ticketUserAddress}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Phone")}
                  </TableCell>
                  <TableCell align="right">
                    {ticketData.ticketUserPhone}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Organization")}
                  </TableCell>
                  <TableCell align="right">
                    {ticketData.ticketUserOrg}
                  </TableCell>
                </TableRow>
                {/* <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Source")}
                  </TableCell>
                  <TableCell align="right">{ticketData.ticketSource}</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        {ticketEdit ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Issue")}
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      id="tUser"
                      className="flex-auto"
                      // onChange={(e) => handleReplyTitle(e)}
                      value={tIssue}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleUpdateIssue}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Description")}
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      id="tUser"
                      className="flex-auto"
                      // onChange={(e) => handleReplyTitle(e)}
                      value={tDesc}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      multiline
                      rows={4}
                      required
                      fullWidth
                      onChange={handleUpdateDesc}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Button
                      variant="outlined"
                      color="error"
                      className=" w-1/2 mt-16"
                      aria-label="Add"
                      type="button"
                      onClick={(e) => setTicketEdit(false)}
                      size="large"
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Button
                      variant="outlined"
                      color="success"
                      className=" w-1/2 mt-16"
                      aria-label="Add"
                      type="submit"
                      size="large"
                      fullWidth
                      onClick={handleUpdateTicket}
                    >
                      {t("Edit-Ticket")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Issue")}
                  </TableCell>
                  <TableCell align="right">{tIssue}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {t("Description")}
                  </TableCell>
                  <TableCell align="right">{tDesc}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      

      {/* {userDataStorage.role !== "Customer" && ( */}
      <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
        <MafiTableTasks
          ticketId={ticketData.ticketId}
          ticketNum={ticketData.ticketNum}
          isClosed={ticketStatus === "Closed"}
        />
      </Box>
      {/* )} */}
      {replies.length > 0 && (
        <Box class="bg-slate-50 p-2 mt-4 mb-8 rounded-lg">
          <TicketThread replies={replies} />
        </Box>
      )}

      <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
        <Typography variant="h5" class="text-xl pt-4 text-blue-900 font-bold">
          {t("Ticket-Images-Subtitle")}
        </Typography>
        <Typography
          class="text-slate-500 mb-4 text-sm"
          sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
        >
          {t("Ticket-Images-Subtitle")}
        </Typography>
        {galleryImages.length > 0 && (
          <MafiImageGallery galleryImages={galleryImages} />
        )}
      </Box>

      </Box>
      {/* </Box> print container */}

      <StyledModal
        open={openReply}
        onClose={(e) => setOpenReply(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box width={500} height={750} class="bg-slate-50/75 p-2 rounded-lg">
          {message}
          <div class="-ml-8 -mr-8">
            <TicketReplays
              ticketId={ticketData.ticketId}
              ticketNumber={ticketData.ticketNum}
              onCreateReply={handleCreateReply}
              onErrorReply={handleErrorReply}
            />
          </div>
        </Box>
      </StyledModal>
      <Dialog
        open={ticketClose}
        onClose={handleCloseTicketClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography variant="h5" class="text-xl pb-4 text-blue-900 font-bold">
            {t("Close-ticket")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            All the ticket's tasks will be close as well. Confirm to close the
            Ticket?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseTicketCancel}>
            Cancel
          </Button>
          <Button onClick={handleCloseTicketClose}>Close Ticket</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ticketDelete}
        onClose={handleCloseTicketDelete}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography variant="h5" class="text-xl pb-4 text-blue-900 font-bold">
            {t("Delete-Ticket")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            All the ticket's tasks and files will be deleted as well. Confirm to
            DELETE the Ticket?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseTicketDelete}>
            Cancel
          </Button>
          <Button onClick={handleCloseTicketDelete}>Delete Ticket</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ticketReOpen}
        onClose={handleTicketReOpen}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography variant="h5" class="text-xl pb-4 text-blue-900 font-bold">
            {t("Reopen Ticket")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm to OPEN the Ticket?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleTicketReOpenCancel}>
            Cancel
          </Button>
          <Button onClick={handleTicketReOpen}>ReOpen Ticket</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TicketDetailMain;
